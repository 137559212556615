.switch {
	width: 54px;
	height: 24px;
	position: relative;

	display: flex;
	align-items: center;

	.switch-track {
		height: 8px;
		width: 100%;
		background-color: rgba(0, 0, 0, 0.25);
	}

	.switch-seeker {
		top: 0;
		left: 0;
		width: 24px;
		height: 24px;
		position: absolute;
		background-color: black;
	}

	&--on {
		.switch-seeker {
			left: calc(100% - 24px);
			background: linear-gradient(
				93.9deg,
				#e2e621 6.2%,
				#488946 28.3%,
				#021475 55.92%,
				#9f03b9 84.44%
			);
		}
	}
}
