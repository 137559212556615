.walltide3dexpmodal {
	@extend .modal;

	.modal-content {
		.subtitle {
			margin-top: 30px;
		}

		.content-wrapper {
			display: flex;
			flex-direction: column;
			align-items: center;

			// margin-top: 50px;

			.switch-wrapper {
				display: flex;
				align-items: center;

				padding: 0 80px;

				span {
					color: black;
					margin: 0 20px;
					font-size: 16px;
				}
			}

			.qrcodes-wrapper {
				padding-top: 30px;
				border-top: 1px solid rgba(0, 0, 0, 0.2);

				margin-top: 30px;

				display: flex;
				flex-direction: row;
				justify-content: center;

				.qrcode {
					display: flex;
					align-items: center;
					flex-direction: column;

					margin-right: 180px;

					&:last-of-type {
						margin-right: 0;
					}

					.label {
						font-size: 18px;
						margin-bottom: 25px;
					}
				}

				&--hidden {
					display: none;
				}
			}

			.connect-app-wrapper {
				margin-top: 30px;

				display: flex;
				align-items: center;
				flex-direction: column;
				justify-content: center;

				padding: 30px 50px;

				background-color: #f7f7f7;

				.title {
					font-size: 24px;
				}

				.connect-button {
					height: 70px;
					width: 450px;
					display: flex;
					align-items: center;
					justify-content: center;

					background-color: white;

					margin: 40px 0;
					font-size: 16px;
					cursor: pointer;

					border-bottom: 4px solid rgba(0, 0, 0, 0.2);
				}

				.wallet-connected-checkbox-wrapper {
					display: flex;
					flex-direction: row;
					align-items: center;

					.checkbox {
						margin-right: 20px;
					}

					span {
						font-size: 16px;
					}
				}

				.wallet-connected-text {
					font-size: 16px;
					margin-top: 40px;
					line-height: 23px;
					text-align: center;
				}
			}

			.next-button {
				margin-top: 80px;

				width: 600px;
				height: 70px;
				cursor: pointer;

				display: flex;
				align-items: center;
				justify-content: center;

				color: white;
				font-size: 25px;
				background-color: black;
			}

			.connect-instructions {
				padding: 0 50px;
				font-size: 16px;
				line-height: 34px;
				margin-bottom: 40px;
			}
		}
	}
}
