.nftcard {
	width: 280px;

	cursor: pointer;
	margin: 0 20px 44px;

	transition: all 0.1s ease-in-out;

	&:hover {
		transform: scale(1.05);
	}

	.nft-card-content-wrapper {
		display: flex;
		align-items: center;
		flex-direction: column;

		&--hidden {
			display: none;
		}

		.image-board {
			width: 100%;
			height: 280px;
			background-color: #f2f2f2;

			display: flex;
			align-items: center;
			justify-content: center;

			.image-wrapper {
				display: flex;
				align-items: center;
				justify-content: center;

				img.real-image {
					border: 0.5px solid #ffffff;
					filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.5));
				}
			}
		}

		.nft-info-wrapper {
			display: flex;
			align-items: center;
			flex-direction: column;
			justify-content: center;

			width: 100%;
			height: 80px;
			border-bottom: 5px solid #c5c5c5;

			.nft-title {
				font-size: 16px;
				text-align: center;
				font-family: 'Press Start 2P';
			}

			.nft-collection {
				color: #7f7f7f;
				font-size: 9px;
				margin-top: 15px;
				font-family: 'Press Start 2P';
			}
		}
	}
}
