.wallridepage {
	width: 100%;
	height: 100%;

	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;

	.title-wrapper {
		display: flex;
		flex-direction: row;

		margin-bottom: 20px;

		.title {
			font-size: 20px;
			color: #707070;
			margin-left: 22px;

			&:first-of-type {
				color: black;
				margin-left: 0;
			}
		}
	}

	.wallride-page-content {
		width: 100%;
		height: 100%;

		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;

		.wallride-info-wrapper {
			// flex: 0.7;
			flex: 1;
			width: 75%;
			height: 100%;

			display: flex;
			align-items: center;
			flex-direction: column;
			justify-content: space-between;

			img {
				flex-grow: 1;
				min-height: 0;
				flex-shrink: 1;
			}

			model-viewer {
				flex-grow: 1;
				min-height: 0;
				flex-shrink: 1;
			}

			// .wallride-image-wrapper {
			// 	flex-grow: 1;
			// 	min-height: 0;
			// 	flex-shrink: 1;

			// 	display: flex;
			// 	align-items: center;
			// 	justify-content: center;

			// 	img {
			// 		width: 80%;
			// 		height: 80%;
			// 		object-fit: contain;
			// 	}
			// }
		}

		.wallride-qr-code-wrapper {
			width: 25%;
			height: 100%;

			display: flex;
			align-items: flex-end;
			justify-content: center;

			.wallride-files-button {
				cursor: pointer;

				display: flex;
				align-items: center;
				justify-content: center;

				width: 100%;
				height: 70px;
				color: white;
				font-size: 15px;
				background-color: black;
			}

			.qr-code-wrapper {
				width: 100%;
				padding: 40px;
				background-color: white;

				display: flex;
				align-items: center;
				flex-direction: column;

				.qr-code-title {
					line-height: 35px;
					font-size: 18px;
					text-align: center;
					margin-bottom: 20px;
				}
			}
		}
	}
}
