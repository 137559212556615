@font-face {
	font-family: 'Press Start 2P';
	src: local('Press Start 2P'),
		url('../fonts/PressStart2P-Regular.ttf') format('truetype');
}

html,
body,
#root {
	margin: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;

	font-family: 'Press Start 2P';
}

@import './homepage.scss';
@import './walletpage.scss';
@import './nftcreation.scss';
@import './wallridepage.scss';
@import './components/index.scss';
