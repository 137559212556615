.aspect-ratio-image {
	.temporary-image {
		display: none;
		position: absolute;
	}

	.real-image {
		object-fit: contain;
	}
}
