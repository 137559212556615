.modal {
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 10000;
	position: fixed;
	background-color: rgba(0, 0, 0, 0.4);

	display: flex;
	align-items: center;
	justify-content: center;

	.modal-content {
		position: relative;

		width: 50%;
		padding: 70px 60px;
		background-color: white;

		box-shadow: 0px 4px 0px rgba(0, 0, 0, 0.25);

		display: flex;
		align-items: center;
		flex-direction: column;

		.title {
			font-size: 35px;
		}

		.subtitle {
			margin-top: 45px;

			font-size: 22px;
		}

		.close-button {
			cursor: pointer;

			top: 8px;
			right: 8px;
			width: 25px;
			height: 25px;
			position: absolute;

			background-size: 100%;
			background-position: center;
			background-repeat: no-repeat;
			background-image: url('../../assets/close.svg');
		}
	}
}
