.walletpage {
	width: 100%;
	height: 100%;
	background-color: #f7f7f7;

	display: flex;
	flex-direction: row;

	.sidebar {
		display: flex;
		align-items: center;
		flex-direction: column;
		background-color: white;

		width: 100px;
		padding-top: 25px;

		.wallride-logo {
			cursor: pointer;
		}

		.sidebar-content-wrapper {
			width: 100%;
			overflow-y: auto;

			.sidebar-content {
				padding: 40px 0;

				display: flex;
				align-items: center;
				flex-direction: column;

				.add-button {
					cursor: pointer;

					width: 55px;
					height: 55px;
					margin-bottom: 50px;
					background-color: black;

					background-size: 35%;
					background-position: center;
					background-repeat: no-repeat;
					background-image: url('../assets/add.svg');
				}
			}
		}
	}

	.wallet-page-content {
		flex: 1;
		padding: 55px 30px;

		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: space-between;

		&--loading {
			justify-content: center;
		}

		.no-nfts {
			display: flex;
			align-items: center;
			justify-content: center;

			height: 77px;
			padding: 0 25px;

			color: white;
			background-color: #981919;
			border: 1px solid #f2f2f2;
		}

		.empty-wallet-image {
			width: 450px;
			height: 590px;
			object-fit: contain;
			background-size: 100%;
			background-position: center;
			background-repeat: no-repeat;
			background-image: url('../assets/empty-wallet.png');
		}

		.create-nft-label-wrapper {
			display: flex;
			align-items: center;

			.create-nft-label {
				font-size: 20px;
			}

			.available-nfts-label {
				font-size: 20px;
				color: #7f7f7f;
				margin-left: 20px;
			}
		}

		model-viewer {
			width: 600px;
			height: 700px;
		}
	}
}
