.nftcreationflow {
	height: 100%;

	.header {
		height: 120px;
		padding: 0 30px;

		display: flex;
		align-items: center;
		flex-direction: row;

		.close-button {
			cursor: pointer;

			width: 40px;
			height: 40px;
			margin-right: 20px;

			background-size: 50%;
			background-position: center;
			background-repeat: no-repeat;
			background-image: url('../assets/close.svg');
		}

		.back-button {
			cursor: pointer;

			width: 40px;
			height: 40px;
			margin-right: 20px;

			background-size: 60%;
			background-position: center;
			background-repeat: no-repeat;
			background-image: url('../assets/back.svg');
		}

		.header-title {
			font-size: 28px;
			font-family: 'Press Start 2P';
		}

		.header-content {
			flex-grow: 1;
		}
	}

	.nftselectionpage {
		height: 100%;
		overflow-y: auto;
		padding-bottom: 20px;

		.nfts-list {
			display: flex;
			flex-wrap: wrap;
			flex-direction: row;

			padding: 20px 100px;
		}
	}

	.nftcreationpage {
		height: 100%;
		overflow-y: auto;
		background-color: white;

		.nftcreationpage-header {
			padding-bottom: 40px;
			background-color: #f7f7f7;

			.nft-image {
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: center;

				img.real-image {
					margin-top: -80px;
					border: 2px solid #ffffff;
					filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.5));
				}
			}
		}

		.nft-info-wrapper {
			padding-top: 70px;
			width: 100%;
			display: flex;
			align-items: center;
			flex-direction: column;

			.nft-title-wrapper {
				display: flex;
				align-items: center;
				flex-direction: row;

				.nft-title {
					font-size: 18px;
					font-family: 'Press Start 2P';
				}

				.nft-collection {
					color: #7f7f7f;
					font-size: 18px;
					margin-left: 20px;
					font-family: 'Press Start 2P';
				}
			}
		}

		.nft-form-wrapper {
			margin-top: 75px;
			padding-bottom: 20px;

			display: flex;
			align-items: center;
			flex-direction: column;

			.nft-fields-wrapper {
				display: flex;
				align-items: center;

				margin-bottom: 45px;

				.textfield {
					width: 140px;
					margin-right: 25px;

					&::last-of-type {
						margin-right: 0;
					}
				}
			}

			.nft-cost-wrapper {
				display: flex;
				align-items: center;
				flex-direction: column;

				.cost {
					font-size: 16px;
					font-family: 'Press Start 2P';
				}

				.flat-rate {
					color: #7f7f7f;
					font-size: 13px;
					margin-top: 20px;
					font-family: 'Press Start 2P';
				}
			}

			.mint-button {
				margin-top: 50px;

				padding: 0 20px;

				width: 450px;
				height: 60px;
				cursor: pointer;

				display: flex;
				align-items: center;
				justify-content: center;

				background-color: black;
				box-shadow: 0px 4px 0px rgba(0, 0, 0, 0.25);

				color: white;
				font-size: 20px;

				&--disabled {
					cursor: default;
					border-bottom: none;
					background-color: #7f7f7f;
				}

				&--loading {
					font-size: 15px;
					justify-content: space-between;
				}
			}
		}
	}
}
