.checkbox {
	width: 30px;
	height: 30px;

	border: 4px solid black;

	display: flex;
	align-items: center;
	justify-content: center;

	cursor: pointer;

	.checkbox-on {
		width: 22px;
		height: 22px;
		background-color: black;
	}
}
