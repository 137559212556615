.tryarexpmodal {
	@extend .modal;

	.modal-content {
		.qrcodes-wrapper {
			margin-top: 70px;

			display: flex;
			flex-direction: row;
			justify-content: center;

			.qrcode {
				display: flex;
				align-items: center;
				flex-direction: column;

				margin-right: 80px;

				&:last-of-type {
					margin-right: 0;
				}

				.label {
					font-size: 18px;
					margin-bottom: 20px;
				}
			}
		}
	}
}
