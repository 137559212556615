.textfield {
	display: flex;
	flex-direction: column;

	span.label {
		color: black;
		font-size: 12px;
		margin-bottom: 10px;
	}

	.input-wrapper {
		display: flex;
		align-items: center;
		flex-direction: row;

		height: 40px;
		background-color: #f2f2f2;

		padding: 0 10px;

		input {
			min-width: 0;
			flex-grow: 1;
			color: black;
			font-size: 16px;

			height: 100%;
			background-color: transparent;

			border: none;
			outline: none;

			font-family: 'Press Start 2P';
		}

		span {
			color: black;
			font-size: 16px;
			text-align: right;
		}
	}
}
