.wallet-connection-badge {
	top: 30px;
	right: 30px;
	position: absolute;

	width: 200px;
	padding: 20px 25px;
	background-color: white;

	cursor: pointer;

	display: flex;
	flex-direction: column;
	justify-content: center;

	.wallet-connection-state {
		font-size: 16px;
	}

	.wallet-public-key {
		font-size: 10px;
		margin-top: 8px;
		color: #7f7f7f;

		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
}
