.unsupported-screen-size-wrapper {
	width: 100%;
	height: 100%;
	background-color: #f7f7f7;

	display: flex;
	flex-direction: column;

	.header {
		flex-shrink: 0;

		height: 90px;
		color: white;
		padding: 0 20px 0 40px;
		background-color: black;

		display: flex;
		align-items: center;
		justify-content: center;
	}

	.page-content {
		flex-grow: 1;
		min-height: 0;
		flex-shrink: 1;
		overflow-y: auto;
		padding: 30px 20px;

		.description {
			padding: 20px;
			background-color: white;

			color: black;
			font-size: 16px;
			line-height: 33px;
		}
	}

	.desktop-message {
		color: white;
		font-size: 16px;
		line-height: 33px;
		text-align: center;

		padding: 40px 20px;
		background-color: #717171;
	}
}

.homepage {
	width: 100%;
	height: 100%;
	background-color: #f7f7f7;

	display: flex;
	flex-direction: column;

	.header {
		flex-shrink: 0;

		height: 90px;
		color: white;
		padding: 0 20px 0 40px;
		background-color: black;

		display: flex;
		align-items: center;

		.wallride-title-info {
			flex-grow: 1;
			display: flex;
			align-items: center;

			.wallride-logo {
				width: 50px;
				height: 50px;
			}

			.wallride-title {
				font-size: 23px;
				margin-left: 50px;
			}
		}

		.launch-button {
			height: 55px;
			padding: 0 25px;

			color: black;
			font-size: 14px;
			cursor: pointer;
			background-color: #f2f2f2;

			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	.homepage-content {
		flex-grow: 1;
		min-height: 0;

		overflow-y: auto;

		display: flex;
		flex-direction: column;

		.wallride-info-wrapper {
			padding: 40px;

			display: flex;
			flex-direction: row;
			align-items: flex-start;

			.wallride-demo-container {
				flex-grow: 1;

				height: 100%;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				justify-content: space-between;

				.wallride-text-container {
					height: 250px;
					padding: 30px;
					text-align: justify;

					font-size: 16px;
					line-height: 25px;
					background-color: white;

					display: flex;
					flex-direction: column;
					align-items: flex-start;
					justify-content: center;

					margin-right: 40px;
				}

				.try-experience-button {
					cursor: pointer;

					height: 50px;
					padding: 0 20px;

					color: white;
					background-color: black;

					display: flex;
					align-items: center;
					justify-content: center;

					box-shadow: 0px 4px 0px rgba(0, 0, 0, 0.25);
				}
			}

			.sample-wallride-wrapper {
				width: 400px;
				height: 400px;
				min-width: 400px;
				min-height: 400px;

				model-viewer {
					width: 100%;
					height: 100%;
					// background-size: 100%;
					// background-position: center;
					// background-repeat: no-repeat;
					// background-image: url('../assets/sample-wallride.png');

					// filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
				}
			}
		}

		.cool-links-wrapper {
			display: flex;
			align-items: center;
			flex-direction: column;
			justify-content: center;
			background-color: white;

			padding: 60px 60px;

			margin: 0 80px 60px;

			.title {
				font-size: 20px;
			}

			.links-wrapper {
				width: 50%;
				min-width: 600px;

				display: flex;
				align-items: center;
				flex-direction: column;

				margin-top: 40px;

				.link-button {
					cursor: pointer;

					height: 65px;
					padding: 0 20px;

					width: 100%;
					max-width: 300px;

					color: black;

					display: flex;
					align-items: center;
					justify-content: center;

					border: 1px solid #f2f2f2;
					box-shadow: 0px 4px 0px rgba(0, 0, 0, 0.25);

					margin-bottom: 20px;

					&:last-of-type {
						margin-bottom: 0;
					}

					&--disabled {
						opacity: 0.6;
						cursor: default;
					}
				}
			}
		}
	}

	.homepage-footer {
		height: 120px;
		flex-shrink: 0;

		padding: 0 40px;
		background-color: black;

		display: flex;
		flex-direction: row;

		color: white;

		.social-wrapper {
			flex-grow: 1;
			display: flex;
			align-items: center;

			.social-link-wrapper {
				&:after {
					content: '/';
					padding: 0 15px;
				}

				&:last-of-type {
					&:after {
						content: '';
					}
				}

				.social-link {
					color: white;
					cursor: pointer;

					font-size: 15px;
				}
			}
		}

		.credits-wrapper {
			display: flex;
			align-items: center;
		}
	}
}
