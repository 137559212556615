.wallridecard {
	margin-bottom: 25px;

	cursor: pointer;

	max-width: 75px;
	max-height: 75px;

	padding: 5px;
	background: transparent;

	display: flex;
	align-items: center;
	justify-content: center;

	&--selected {
		background-color: black;

		.aspect-ratio-image {
			border: 1px solid white;
		}
	}
}
