.connectwalletmodal {
	@extend .modal;

	.modal-content {
		.wallets-wrapper {
			margin-top: 40px;

			.wallet-button {
				margin-bottom: 15px;

				width: 450px;
				height: 60px;
				cursor: pointer;

				display: flex;
				align-items: center;
				justify-content: center;

				background-color: black;

				color: white;
				font-size: 20px;

				&:first-of-type {
					margin-bottom: 0;
				}
			}
		}
	}
}
